import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Modal, Button } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { jsx, css } from '@emotion/react'

import Layout from '../components/layout'
import Newsletter from '../components/Newsletter'

const IndexPage = ({ data, location }) => {
  const [show, setShow] = useState(false)
  const [modalContent, setModalContent] = useState({})

  const handleClose = () => setShow(false)

  const handleResults = (res) => {
    if (res?.Code === 0) {
      setModalContent({
        title: data.markdownRemark.frontmatter.successTitle,
        body: data.markdownRemark.frontmatter.successMessage,
      })
    } else if (res?.Code === 501) {
      setModalContent({
        title: data.markdownRemark.frontmatter.errorTitle,
        body: data.markdownRemark.frontmatter.invalidEmailMessage,
      })
    } else {
      setModalContent({
        title: data.markdownRemark.frontmatter.errorTitle,
        body: data.markdownRemark.frontmatter.defaultErrorMessage,
      })
    }
    setShow(true)
  }

  const mainWrapper = css`
    display: grid;
    grid-template-rows: 50% 50%;
  `

  const imageStyle = css`
    width: 100vw;
    height: 100vh;
    grid-row: 1/3;
    grid-column: 1/1;
  `

  const titleWrapperStyle = css`
    grid-row: 1/2;
    grid-column: 1/1;
    width: 1058px;
    padding: 20px;
    justify-self: center;
    align-self: center;
    @media (max-width: 1100px) {
      width: 100%;
    }
  `

  const newsletterWrapper = css`
    grid-row: 2/3;
    grid-column: 1/1;
    position: relative;
    width: 900px;
    padding: 10px;
    justify-self: center;
    align-self: start;

    @media (max-width: 1100px) {
      width: 100%;
    }
  `

  return (
    <Layout
      location={location}
      title={data.site.siteMetadata?.title || `Title`}>
      <section css={mainWrapper}>
        <StaticImage
          css={imageStyle}
          layout="fullWidth"
          src="../images/splash-screen.png"
          alt="topside splash screen"
          quality="90"
          loading="eager"
          placeholder="blurred"
        />
        <div css={titleWrapperStyle}>
          <StaticImage
            layout="fullWidth"
            src="../images/title.png"
            alt="topside title"
            quality="50"
            loading="eager"
            placeholder="none"
          />
        </div>
        <div css={newsletterWrapper}>
          <Newsletter handleResults={handleResults} />
        </div>
      </section>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent.body}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            {data.markdownRemark.frontmatter.buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        successTitle
        successMessage
        errorTitle
        defaultErrorMessage
        invalidEmailMessage
        buttonText
      }
    }
  }
`
