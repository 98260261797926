import React, { useState } from 'react'
import axios from 'axios'
import { jsx, css } from '@emotion/react'

const formStyle = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  input {
    width: 100%;
    max-width: 503px;
    padding: 9px;
    border: solid 5px #353535;
    border-radius: 9px;
    font-size: 29px;
    background-color: #ededed;
    @media (max-width: 1100px) {
      margin-bottom: 20px;
      font-size: 24px;
    }
    &:focus {
      outline: none;
    }
  }
`

const buttonStyle = css`
  font-family: 'Amatic SC', sans-serif;
  border: solid 5px #353535;
  border-radius: 9px;
  white-space: nowrap;
  padding: 6px 18px;
  font-size: 50px;
  background-color: #67528a;
  color: white;
  margin: 0 0 0 8px;
  cursor: pointer;
  @media (max-width: 1100px) {
    font-size: 30px;
  }
`

const Newsletter = ({ handleResults }) => {
  const [email, setEmail] = useState('')

  async function registerEmail(e) {
    e.preventDefault()
    let result
    try {
      result = await axios({
        method: 'POST',
        url: '/.netlify/functions/newsletter',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: { email },
      })
    } catch (err) {
      result = {
        data: {
          status: err.response.status || 500,
          message: err.response.data,
        },
      }
    }
    setEmail('')
    handleResults(result.data)
  }

  return (
    <form css={formStyle}>
      <input
        type="text"
        value={email}
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <button css={buttonStyle} onClick={registerEmail}>
        Discover what's coming
      </button>
    </form>
  )
}

export default Newsletter
